// add account history + past orders and prices

import React, { Component } from "react"

import Layout from "components/layout"
import SEO from "components/seo"

import { doSignOut } from "api/auth"
import { fetchUserDataByUid } from "api/db"

class Account extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: {},
    }
  }

  componentDidMount() {
    this.getUserData(JSON.parse(localStorage.getItem("uid")))
  }

  getUserData = uid => {
    fetchUserDataByUid(uid).on(
      "value",
      function(snapshot) {
        let data = snapshot.val()
        this.setState({
          user: data,
        })
      }.bind(this)
    )
  }

  render() {
    return (
      <Layout>
        <SEO title="Account | Tick Tock Inc." />
        <section className="ticktockinc-container">
          <h1 className="is-size-3 has-text-weight-bold">
            {" "}
            {this.state.user.companyName}
          </h1>
          <p className="is-size-4"> {this.state.user.companyAddress}</p>
          <p className="is-size-4">
            {" "}
            {this.state.user.companyCity}, {this.state.user.companyState}{" "}
          </p>
          <br />
          <p className="is-size-4">
            {" "}
            Account Administrator: {this.state.user.userName},{" "}
            {this.state.user.userPosition}{" "}
          </p>
          <br />

          <button
            className="button is-danger is-rounded"
            onClick={() => doSignOut()}
          >
            {" "}
            Log Out{" "}
          </button>
        </section>
      </Layout>
    )
  }
}

export default Account
